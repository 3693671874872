<!-- 管理员设置评卷 -->
<template>
  <div>
    <el-row style="margin-bottom: 10px">
      <el-button
        v-throttle
        size="medium"
        @click="
          () => {
            this.$router.back()
          }
        "
        >返回</el-button
      >
    </el-row>
    <el-form :inline="true" :model="examineeMsgCondition">
      <el-form-item label="科目" class="formItemBoxStyle">
        <el-select
          v-model="examineeMsgCondition.subjectType"
          placeholder="请选择学科"
          @change="reloadSubjectType"
        >
          <!-- <el-option label="全部" value></el-option> -->
          <el-option
            v-for="val in examineeMsgConditionList.subjectType"
            :key="val"
            :label="val | subjectTypeFilters"
            :value="val"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试批次" class="formItemBoxStyle">
        <el-select
          v-model="examineeMsgCondition.examSession"
          placeholder="请选择考试批次"
          clearable
          @change="reloadSession"
        >
          <!-- <el-option label="全部" value></el-option> -->
          <el-option
            v-for="val in examineeMsgConditionList.examSession"
            :key="val"
            :label="val"
            :value="val"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="考生编号" class="formItemBoxStyle">
        <el-input
          v-model="examineeMsgCondition.examineeCode"
          placeholder="请输入考生编号"
          clearable
          style="width: 200px"
          @input="reloadExamineeMsg"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-row v-loading="tableLoading">
      <el-table
      border
      element-loading-text="数据载中请稍等"
      ref="examineeMsgDate"
      :data="examineeMsgDate"
      tooltip-effect="dark"
      style="width: 100%"
      :header-cell-style="{
        'text-align': 'center',
        backgroundColor: '#dfe6ec',
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <!-- 
        default-expand-all
        row-key="examId"
        :tree-props="{children: 'examInfoList'}"
          -->
      <el-table-column
        prop="examSession"
        label="考试批次"
        width="80"
      ></el-table-column>
      <el-table-column prop="examTime" label="考试时间" width="110">
        <template slot-scope="scope">{{
          scope.row.examTime | dayList
        }}</template>
      </el-table-column>
      <el-table-column prop="studentName" label="学生姓名"></el-table-column>
      <el-table-column prop="examineeCode" label="考生编号"></el-table-column>
      <el-table-column label="科目" width="80">
        <template slot-scope="scope">{{
          scope.row.subjectType | subjectTypeFilters
        }}</template>
      </el-table-column>
      <el-table-column label="考生状态" width="120">
        <template slot-scope="scope">{{
          scope.row.examineeStatus | examineeStatusFilters
        }}</template>
      </el-table-column>
      <el-table-column label="考生的考试视频状态" width="150">
        <template slot-scope="scope">
          <span style="color: red" v-if="!!scope.row.videoErrorStatus"
            >异常</span
          >
          <span v-else>正常</span>
        </template>
      </el-table-column>
      <el-table-column label="异常信息" width="150">
        <template slot-scope="scope">
          <span>{{ handleFindOutErrorInfo(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="730">
        <template slot-scope="scope">
          <el-button
            v-throttle
            v-if="scope.row.examineeStatus != 5"
            size="small"
            type="danger"
            @click="disqualificationBtn(scope.row)"
            >取消考试资格</el-button
          >
          <el-button
            v-throttle
            v-if="scope.row.examineeStatus == 5"
            size="small"
            type="info"
            :disabled="true"
            >已取消考试资格</el-button
          >
          <el-button
            v-throttle
            size="small"
            type="primary"
            @click="videoBtn(scope.row, 1)"
            >摄像头1</el-button
          >
          <el-button
            v-throttle
            size="small"
            type="primary"
            @click="videoBtn(scope.row, 2)"
            >摄像头2</el-button
          >
          <el-button
            v-throttle
            size="small"
            type="primary"
            @click="videoBtn(scope.row, 3)"
            >摄像头3</el-button
          >
          <el-upload
            style="display: inline-block; margin-left: 10px"
            :limit="1"
            action="https://jsonplaceholder.typicode.com/posts/"
            :http-request="
              (e) => {
                uploadNewVideoBtn(e, scope.row, 1)
              }
            "
            :show-file-list="false"
            ref="upload"
          >
            <!-- @click="uploadNewVideoBtn(scope.row,1)" -->
            <el-button v-throttle size="small" type="success"
              >重传摄像头1</el-button
            >
          </el-upload>
          <el-upload
            style="display: inline-block; margin-left: 10px"
            :limit="1"
            action="https://jsonplaceholder.typicode.com/posts/"
            :http-request="
              (e) => {
                uploadNewVideoBtn(e, scope.row, 2)
              }
            "
            :show-file-list="false"
            ref="upload"
          >
            <!--  @click="uploadNewVideoBtn(scope.row,2)" -->
            <el-button v-throttle size="small" type="success"
              >重传摄像头2</el-button
            >
          </el-upload>
          <el-upload
            style="display: inline-block; margin-left: 10px"
            :limit="1"
            action="https://jsonplaceholder.typicode.com/posts/"
            :http-request="
              (e) => {
                uploadNewVideoBtn(e, scope.row, 3)
              }
            "
            :show-file-list="false"
            ref="upload"
          >
            <!--  @click="uploadNewVideoBtn(scope.row,2)" -->
            <el-button v-throttle size="small" type="success"
              >重传摄像头3</el-button
            >
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChangeExamineeMsgPage"
        @current-change="handleCurrentChangeExamineeMsgPage"
        :current-page="examineeMsgPage.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="examineeMsgPage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="examineeMsgPage.totalSize"
      ></el-pagination>
    </el-row>
    </el-row>
    
    <!-- 弹框 -->
    <el-dialog
      width="30%"
      title="取消考生考试资格"
      :visible.sync="disqualificationBtnDialog"
      append-to-body
    >
      <el-row style="margin-bottom: 20px; text-align: center">
        <el-col :span="12"
          >考生学号：{{ disqualificationData.examineeCode }}</el-col
        >
        <el-col :span="6"
          >学生姓名：{{ disqualificationData.studentName }}</el-col
        >
        <el-col :span="6"
          >考试学科：{{
            disqualificationData.subjectType | subjectTypeFilters
          }}</el-col
        >
      </el-row>
      <el-form
        ref="disqualificationCondition"
        :model="disqualificationCondition"
        label-width="70px"
      >
        <el-form-item label="取消原因">
          <el-input
            type="textarea"
            v-model.trim="disqualificationCondition.exceptionReason"
            maxlength="90"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-throttle
          size="medium"
          @click="disqualificationBtnDialog = false"
          >取 消</el-button
        >
        <el-button
          v-throttle
          size="medium"
          type="primary"
          @click="updateExamineeStatusFunc"
          >确认取消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      width="1080"
      title="视频播放"
      :visible.sync="videoDialog"
      append-to-body
    >
      <video
        width="100%"
        autoplay
        :src="this.$imageAddress(this.videoUrl)"
      ></video>
    </el-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import SparkMD5 from 'spark-md5'
import { Loading } from 'element-ui'
import {
  examineeMsgPage,
  examTimeSession,
  updateExamineeStatus,
  uploadVideo,
} from '@/api/marking/abnormal.js'
export default {
  components: {},
  // 异常处理转跳
  name: 'abnormalDialog',
  data() {
    return {
      tableLoading:false,
      // 分页条件查询考试考生信息和视频信息
      examineeMsgCondition: {
        examSession: '',
        examSysOrgAreaId: 0,
        examSysOrgExamPlaceId: 0,
        examSysOrgExamPlaceRoomId: 0,
        examineeCode: '',
        examineeStatus: 0,
        examineeVideoStatus: 0,
        orgAreaId: 0,
        orgSchoolId: 0,
        studentCode: 0,
        studentName: '',
        subjectType: '',
      },
      examineeMsgDate: [],
      examineeMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 分页可选文件
      examineeMsgConditionList: {
        examSession: [],
        examSysOrgAreaId: [],
        examSysOrgExamPlaceId: [],
        examSysOrgExamPlaceRoomId: [],
        examineeCode: '',
        examineeStatus: [],
        examineeVideoStatus: [],
        orgAreaId: [],
        orgSchoolId: [],
        studentCode: '',
        studentName: '',
        subjectType: [],
        examId: '',
      },
      videoDialog: false,
      disqualificationBtnDialog: false,
      videoUrl: '',
      // 取消考生考试资格
      examExamineeId: 0,
      disqualificationCondition: {
        examineeStatus: 5,
        exceptionReason: '',
      },
      disqualificationData: {
        studentName: '',
        subjectType: '',
        examineeCode: '',
      },
    }
  },
  watch: {},
  created() {},
  async mounted() {
    let examInfo = JSON.parse(this.$route.query.examInfo)
    this.examineeMsgConditionList.subjectType = examInfo.subjectTypes
    this.examineeMsgConditionList.examId = examInfo.examId
    this.examineeMsgCondition.subjectType = examInfo.subjectTypes[0]

    await this.examineeMsgPageFunc(examInfo.examId, {
      subjectType: this.examineeMsgCondition.subjectType,
    })
    await this.examTimeSessionFunc(
      this.examineeMsgConditionList.examId,
      this.examineeMsgCondition.subjectType
    )
  },
  methods: {
    // 考试批次
    examTimeSessionFunc(
      examId = this.examineeMsgConditionList.examId,
      subjectType
    ) {
      // return new Promise((resolve, reject) => {
      examTimeSession(examId, subjectType)
        .then((res) => {
          console.log('考试批次', res)
          if (res.success) {
            this.examineeMsgConditionList.examSession = Array.from(
              new Set(res.data.map((val) => val.examSession))
            )
          }
          // resolve()
        })
        .catch((err) => {
          // resolve()
        })
      // })
    },
    // 异常表格数据
    examineeMsgPageFunc(
      examId = this.examineeMsgConditionList.examId,
      data = this.examineeMsgCondition
    ) {
      this.tableLoading = true
      // return new Promise((resolve, reject) => {
      examineeMsgPage(
        examId,
        this.examineeMsgPage.pageIndex,
        this.examineeMsgPage.pageSize,
        data
      )
        .then((res) => {
          this.tableLoading = false
          console.log('弹框列表', res)
          if (res.success) {
            this.examineeMsgDate = res.data
            this.examineeMsgPage.totalSize = res.total
          } else {
            this.$message.info(res.msg)
          }
          // resolve()
        })
        .catch((err) => {
          this.$message.info('请求失败')
          // resolve()
        })
      // })
    },
    handleSizeChangeExamineeMsgPage(val) {
      this.examineeMsgPage.pageSize = val
      this.examineeMsgPage.pageIndex = 1
      this.examineeMsgPageFunc()
    },
    handleCurrentChangeExamineeMsgPage(val) {
      this.examineeMsgPage.pageIndex = val
      this.examineeMsgPageFunc()
    },
    reloadSubjectType() {
      this.examineeMsgCondition.examSession = ''
      this.examineeMsgCondition.examineeCode = ''
      this.examineeMsgConditionList.examSession = []
      if (!!this.examineeMsgCondition.subjectType) {
        this.examTimeSessionFunc(
          this.examineeMsgConditionList.examId,
          this.examineeMsgCondition.subjectType
        )
      }
      this.reloadExamineeMsg()
    },
    reloadSession() {
      this.examineeMsgCondition.examineeCode = ''
      this.reloadExamineeMsg()
    },
    reloadExamineeMsg() {
      this.examineeMsgPage.pageIndex = 1
      this.examineeMsgPageFunc()
    },
    // 取消资格弹框
    disqualificationBtn(val) {
      console.log('val', val)
      this.disqualificationData = {
        studentName: val.studentName,
        subjectType: val.subjectType,
        examineeCode: val.examineeCode,
      }
      this.disqualificationCondition.exceptionReason = ''
      this.examExamineeId = val.examineeId
      this.disqualificationBtnDialog = true
    },
    // 取消资格
    updateExamineeStatusFunc() {
      if (this.disqualificationCondition.exceptionReason.length == 0)
        return this.$message.error('取消原因不能为空')
      updateExamineeStatus(
        this.examExamineeId,
        this.disqualificationCondition
      ).then((res) => {
        console.log('取消资格', res)
        if (res.success && res.data) {
          this.examineeMsgPageFunc()
          this.$message.success('已取消该考生考试资格')
          this.disqualificationBtnDialog = false
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 视频详情
    videoBtn(val, key) {
      this.videoUrl = ''
      console.log('videoDTOList', val.videoDTOList)
      console.log('videoDTOList', val.videoDTOList instanceof Array)
      if (!val.videoDTOList.length) {
        return this.$message.info('无视频')
      }
      this.videoUrl = val.videoDTOList.filter(
        (e) => e.videoType == key
      )[0]?.videoUrl
      if (!this.videoUrl) {
        return this.$message.info('无视频')
      }
      this.videoDialog = true
    },
    // 重传视频
    async uploadNewVideoBtn(e, val, key) {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: '上传中',
      })
      console.log('重传视频', e, val, key)
      let videoSourceMd5 = await this.doIncrementalTest(e.file),
        fd = new FormData()
      fd.append('examineeId', val.examineeId)
      fd.append('file', e.file)
      fd.append('videoSourceMd5', videoSourceMd5)
      fd.append('videoType', key)
      uploadVideo(fd)
        .then((res) => {
          console.log('重传视频', res)
          if (res.success) {
            this.$message.success('上传成功')
            this.examineeMsgPageFunc()
          } else {
            this.$message.error(res.msg)
          }
          loadingInstance.close()
          this.$refs.upload.clearFiles() // 清空历史记录
        })
        .catch((err) => {
          this.$refs.upload.clearFiles() // 清空历史记录
          loadingInstance.close()
        })
    },
    //上传 文件转md5
    doIncrementalTest(file) {
      return new Promise((resolve, reject) => {
        var running = false //running用于判断是否正在计算md5
        //这里假设直接将文件选择框的dom引用传入
        if (running) {
          return
        }
        //这里需要用到File的slice( )方法，以下是兼容写法
        var blobSlice =
            File.prototype.slice ||
            File.prototype.mozSlice ||
            File.prototype.webkitSlice,
          chunkSize = 2097152, // 以每片2MB大小来逐次读取
          chunks = Math.ceil(file.size / chunkSize),
          currentChunk = 0,
          spark = new SparkMD5(), //创建SparkMD5的实例
          fileReader = new FileReader()
        fileReader.onload = function (e) {
          console.log('Read chunk number (currentChunk + 1) of  chunks ')
          spark.appendBinary(e.target.result) // append array buffer
          currentChunk += 1
          if (currentChunk < chunks) {
            loadNext()
          } else {
            running = false
            let str = spark.end()
            console.log('str', str)
            resolve(str)
            // return str // 完成计算，返回结果
          }
        }
        fileReader.onerror = function () {
          running = false
          console.log('读文件失败')
          this.$message.error('读文件失败')
          reject('读文件失败')
        }
        function loadNext() {
          var start = currentChunk * chunkSize,
            end = start + chunkSize >= file.size ? file.size : start + chunkSize
          fileReader.readAsBinaryString(blobSlice.call(file, start, end))
        }
        running = true
        loadNext()
      })
    },
    // 找出视频异常信息
    handleFindOutErrorInfo(row) {
      let result = '/'
      for (let i = 0; i < row.videoDTOList.length; i++) {
        if (row.videoDTOList[i].errorInfo) {
          result = row.videoDTOList[i].errorInfo
          return result
        }
      }
      if (row.videoErrorStatus === 1) {
        return row.videoErrorInfo
      }
      return result
    },
  },
  filters: {
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
        default:
          return ``
      }
    },
    examineeStatusFilters(key) {
      switch (key) {
        case 1:
          return `待交卷`
        case 2:
          return `待阅卷`
        case 3:
          return `已阅卷`
        case 4:
          return `缺考`
        case 5:
          return `取消考试`
        default:
          return `${key}`
      }
    },
    dayList(val) {
      return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
    },
  },
}
</script>
<style lang="scss" scoped></style>
